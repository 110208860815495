<template>
  <Header />
  <Suspense>
    <template #default>
       <Lows />
    </template>
    <template #fallback>
       <Loding />
    </template>
  </Suspense>
  <Footer />
</template>

<script>
import { Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import Header from "@/components/Include/Header.vue";
import Footer from "@/components/Include/Footer.vue";
import Lows from "@/components/Categorys/Lows.vue";
export default {
  name: 'Low',
   components: {
    Suspense,
    Loding,
    Header,
    Footer,
    Lows
  },
}
</script>

<style>

</style>